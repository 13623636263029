.scanner-bg-container {
  margin-top: 40px;
  margin-bottom: 20px;
  padding: 20px 30px;

  .link-title {
    font-family: AvenirLTStdBook;
    font-size: 14px;
    line-height: 1.2;
    color: #1890ff;
    cursor: pointer;
  }

  .title {
    font-family: AvenirLTStdBook;
    font-size: 14px;
    line-height: 1.2;
    color: #000;
  }

  .bread-crumb-container {
    align-items: center;
    margin-bottom: 12px;
    margin-top: 12px;
  }

  @media (max-width: 576px) {
    .bread-crumb-container {
      height: 40px;
      margin-bottom: 0px;
      margin-top: 0px;
    }
  }

  .bread-crumb {
    font-family: AvenirLTStdBook;
    margin-right: 8px;
    margin-left: 8px;
    font-size: 14px;
    color: #000;
  }

  .breadcrumb-divider::before {
    font-size: 16px;
    color: #000000;
  }

  .text-heading-black {
    font-family: AvenirLTStdHeavy;
    font-size: 20px;
    line-height: 1.2;
    color: #000000;
    margin-bottom: 0px;
  }

  .standard-font {
    font-family: AvenirLTStdHeavy;
    font-size: 16px;
    line-height: 1.2;
    color: #000000;
    margin-bottom: 0px;
  }

  .tag-heading {
    color: #000000;
    font-family: AvenirLTStdHeavy;
    line-height: 1.2;
    font-size: 16px;
  }

  .tag-help {
    font-size: 16px;
  }

  .tag-container {
    margin-top: 20px;
  }

  .tag-button {
    height: 36px;
    width: 80px;
    background-color: #fff;
    color: #050593;
    border: 1px solid #050593;
    font-family: AvenirLTStdBook;
    border-radius: 4px;
    font-size: 16px;
  }

  .margin-top-20 {
    margin-top: 20px;
  }

  .margin-bottom-20 {
    margin-bottom: 20px !important;
  }

  .scan-input {
    height: 36px;
    border-color: #aaa;
    font-family: AvenirLTStdBook;
    font-size: 16px;
    color: #000 !important;
  }

  .scan-input::placeholder {
    height: 40px;
    font-family: AvenirLTStdBook;
    font-size: 16px;
    color: #bbb !important;
  }

  @media (max-width: 460px) {
    .scan-input::placeholder {
      font-size: 13.5px;
    }
  }

  .scanInputContainer {
    background-color: #fff;
    border-top: none;
    padding: 20px;
    min-height: 60px;
  }

  .empty-notification-container {
    background-color: #fff;
    box-shadow: 0px 0px 4px 4px #f0f0f0;
    min-height: 320px;
    box-sizing: border-box;
    border-bottom: none;
  }

  .empty-notification-text {
    font-size: 16px;
    color: #000000;
    font-family: AvenirLTStdBook;
    line-height: 1.2;
  }

  .error-notification-container {
    background-color: #e31920;
    min-height: 320px;
    box-sizing: border-box;
    width: 100%;
  }

  .error-notification-text {
    font-size: 36px;
    color: #ffffff;
    font-family: AvenirLTStdBook;
    line-height: 1.2;
    margin-top: 10px;
    overflow-wrap: anywhere;
  }

  .json-notification-text {
    font-size: 10px;
    color: #ffffff;
    font-family: AvenirLTStdBook;
    line-height: 1.2;
    margin-top: 10px;
    overflow-wrap: anywhere;
  }

  @media (max-width: 850px) {
    .error-notification-text {
      font-size: 26px;
      overflow-wrap: anywhere;
    }
  }

  .success-notification-container {
    background-color: #188c15;
    min-height: 320px;
    box-sizing: border-box;
    line-height: 1.2;
  }

  .success-notification-100-bold {
    color: #fff;
    font-size: 100px;
    font-family: AvenirLTStdHeavy;
  }

  .success-notification-50-bold {
    color: #fff;
    font-size: 50px;
    font-family: AvenirLTStdHeavy;
    text-transform: uppercase !important;
    margin-bottom: 0px;
  }

  .success-notification-40-bold {
    color: #fff;
    font-size: 40px;
    font-family: AvenirLTStdHeavy;
    margin-bottom: 20px;
  }

  @media (max-width: 576px) {
    .success-notification-40-bold {
      font-size: 35px;
    }
  }

  .success-notification-25-bold {
    color: #fff;
    font-size: 25px;
    font-family: AvenirLTStdHeavy;
    text-transform: uppercase !important;
    margin-bottom: 20px;
  }

  .success-notification-25 {
    color: #fff;
    font-size: 25px;
    font-family: AvenirLTStdBook;
  }

  .success-notification-20 {
    color: #fff;
    font-size: 20px;
    font-family: AvenirLTStdBook;
    margin-bottom: 10px;
  }

  .success-notification-standard-font {
    color: #fff;
    font-size: 16px;
    font-family: AvenirLTStdBook;
    margin-bottom: 10px;
  }

  .success-notification-standard-uppercase {
    color: #fff;
    font-size: 16px;
    font-family: AvenirLTStdBook;
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  // .notification-country {
  //   color: #fff;
  //   font-size: 50px;
  //   font-weight: bold;
  //   font-family: AvenirLTStdHeavy;
  //   text-transform: uppercase !important;
  //   margin-bottom: 0px;
  // }

  // .notification-postal-code {
  //   color: #fff;
  //   font-size: 100px;
  //   font-family: AvenirLTStdHeavy;
  // }

  // .notification-state-city {
  //   color: #fff;
  //   font-size: 16px;
  //   font-family: AvenirLTStdBook;
  //   margin-bottom: 10px;
  // }

  // .notification-shipper-name {
  //   color: #fff;
  //   font-size: 16px;
  //   font-family: AvenirLTStdBook;
  //   text-transform: uppercase;
  //   margin-bottom: 10px;
  // }

  // .notification-scan-number {
  //   color: #fff;
  //   font-size: 25px;
  //   font-family: AvenirLTStdBook;
  // }

  .action-item {
    padding: 20px;
    background-color: #fff;
    cursor: pointer;
    height: 60px;
  }

  .action-item-box-shadow {
    box-shadow: 0 4px 2px 1px #f1f1f1;
  }

  .action-item-top-border {
    border-top: 1px solid rgba(0, 0, 0, 0.125);
  }

  .action-item-arrow {
    font-family: AvenirLTStdBook;
    font-size: 19px;
    line-height: 1.2;
    color: #000000;
  }

  .action-item-image {
    width: 30px;
    height: 30px;
    margin-right: 20px;
  }

  .action-item-margin {
    margin-top: 20px;
  }

  .success-text {
    font-family: AvenirLTStdBook;
    line-height: 1.2;
    color: #1dd1a1;
    font-size: 16px;
    font-weight: normal;
    margin-left: 15px;
  }

  .error-text {
    font-family: AvenirLTStdBook;
    line-height: 1.2;
    color: #bc1a1a;
    font-size: 16px;
    font-weight: normal;
    margin-left: 15px;
  }

  .warning-notification-container {
    background-color: #ffbf00;
    min-height: 340px;
    box-sizing: border-box;
    line-height: 1.2;
  }

  .update-weight-container {
    padding: unset;
    width: 100%;
    margin-top: 0 !important;
  }

  .update-weight-label {
    font-family: AvenirLTStdBook;
    line-height: 1.2;
    color: #000000;
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: normal;
  }

  .update-weight-input-container {
    margin-right: 5px;
  }

  .update-weight-button-container {
    margin-top: 30px;
  }

  @media (max-width: 576px) {
    .text-heading-black-font-sm {
      font-size: 18px;
    }
  }
}

@media (max-width: 576px) {
  .scanner-bg-container {
    height: auto;
    width: 100%;
    padding: 0px;
    margin-top: 50px;
  }

  .update-weight-container {
    .update-dimen-input {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

@media (max-width: 767px) {
  .scanner-bg-container {
    .row {
      margin: 0;
      padding: 0;
    }
    .col-md-6,
    .col-sm-12 {
      padding: 0;
    }
  }

  .update-weight-container {
    .update-dimen-input {
      padding-left: 0;
    }

    .update-dimen-input--pr-0 {
      padding-right: 0;
    }
  }
}

.action-settings-buttons {
  height: 25px;
  background-color: #fff;
  color: rgb(138, 138, 138);
  font-family: AvenirLTStdBook;
  font-size: 14px;
  min-width: 45px;
  margin-left: 10px;
  margin-right: 18px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #707070;
  box-sizing: border-box;
  border-radius: 4px;
}

.action-settings-buttons-color {
  height: 25px;
  background-color: #fff;
  color: #050593;
  font-family: AvenirLTStdBook;
  font-size: 14px;
  border-radius: 4px;
  min-width: 45px;
  border-color: #050593;
  margin-left: 2px;
  border-width: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.action-settings-buttons-color-margin-left {
  margin-right: 18px !important;
}
