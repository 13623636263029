.top-container {
  background: #050593;
}

.logo-container {
  width: 250px;
  max-width: 80%;
}

.content-container {
  max-width: 96%;
  min-width: 50%;
}

.divider {
  width: 0;
  border-top: 6vh solid #050593;
  border-left: 50vw solid transparent;
  border-right: 50vw solid transparent;
  border-bottom: 0;
  background: #fff;
  background-color: rgb(255, 255, 255);
  background-color: transparent;
}

.divider-scanner {
  border-top: 3vh solid #000028 !important;
}

.btn-janio {
  background: linear-gradient(170deg, #050593 0%, #000455 100%) !important;
  border: 0px !important;
}

.btn-grey {
  background: linear-gradient(170deg, #cccccc 0%, #cccccc 100%) !important;
  color: #666666 !important;
  border: 0px !important;
}

.btn-janio-light {
  background: rgba(5, 5, 147, 0.3);
  border: 1.5px solid #050593;
}

.btn-janio-redirect {
  height: 100px;
}

.rounded-btn {
  box-sizing: border-box;
  border-radius: 4px;
}

.camera-btn {
  width: 45px;
}

.scan-info-screen {
  min-height: 238px;
}

.scan-no-info {
  background: #e5e5e5;
}

.scan-no-info > label,
.scan-status > div {
  width: 100%;
  height: 22px;
  text-align: center;
  color: rgba(77, 77, 77, 0.5);
}

.scan-status {
  height: 72px;
  background: #e5e5e5;
}

.parcel-count-dark {
  color: #4d4d4d;
}

.hms-inbound {
  height: 72px;
  width: 100%;
  border-top: 1px solid #e5e5e5;
}

.hms-inbound > label {
  text-align: center;
  width: 100%;
  text-transform: uppercase;
  margin-top: 18px;
}

.hms-inbound > .inbound-label {
  font-size: 24px;
  text-transform: uppercase;
}

.hms-inbound > .tracking-number {
  font-size: 17px;
}

.valid-data-container {
  border: 2px solid #1abc9c;
}

.valid-sort-container {
  background-color: #1dd1a1;
  color: #fff;
}

.valid-sort-container > div > div {
  position: absolute;
  top: 30%;
  width: 100%;
}

.valid-sort-container > div > div > label {
  text-transform: uppercase;
  /* position: absolute; */
  /* top: 40%; */
  width: 100%;
  text-align: center;
}

.line1 {
  font-size: 28px;
}

.otherlines {
  font-size: 14px;
}

.error-data-container {
  border: 2px solid #c70007;
}

.scan-error-info {
  background: #e31920;
  text-align: center;
  font-size: 18px;
  color: #fff;
  text-transform: capitalize;
}

.scan-error-info > .err-img {
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
}

.err-tracking-number {
  text-transform: none;
}

.err-no-data {
  width: 100px;
  height: 76px;
  background-image: url("../images/old-icons/no-data.png");
}
.err-duplicate-tn {
  width: 67px;
  height: 74px;
  background-image: url("../images/old-icons/duplicate-tn.png");
}

.valid-return-inbound-container {
  min-height: 238px;
  background-color: #89158c;
}

.valid-return-inbound-container > .return-inbound-exception {
  color: #fff;
  font-size: 16px;
  font-family: AvenirLTStdBook;
  text-transform: uppercase !important;
  margin-bottom: 20px !important;
}

.valid-return-inbound-container > .return-inbound-to-do-next {
  color: #fff;
  font-size: 30px;
  font-family: AvenirLTStdHeavy;
  text-transform: uppercase !important;
  margin-bottom: 20px !important;
}

.valid-return-inbound-container > .return-inbound-attempts {
  color: #fff;
  font-size: 15px;
  font-family: AvenirLTStdBook;
  margin-bottom: 20px !important;
}

.valid-return-inbound-container > .return-inbound-scan-number {
  color: #fff;
  font-size: 15px;
  font-family: AvenirLTStdBook;
  text-transform: uppercase !important;
}

.scan-valid-info {
  background: #fff;
}

.scan-valid-info > .country-name {
  width: 100%;
  height: 36px;
  text-align: center;
  background-color: #1dd1a1;
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  padding-top: 4px;
  margin-top: 10px;
}

.scan-valid-info > div > .meta-info {
  float: left;
  width: 33.33%;
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;
  /* margin-top: 12px; */
}

.green-border-right {
  border-right: 2px solid #1abc9c;
}

.green-border-left {
  border-left: 2px solid #1abc9c;
}

.scan-valid-info > div > .destination-hub {
  width: 100%;
  text-align: center;
  font-size: 16px;
  margin-top: 12px;
}

.scan-valid-info > div > .merchant-name,
.scan-valid-info > div > .order-value {
  width: 100%;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
}

.react-datepicker-wrapper {
  width: 100%;
}

.dimweight-input-group > input {
  float: left;
  width: 69px;
  margin-right: 5px;
  padding: 0.375rem 0.75rem;
}

.success-green {
  background-color: #6cccb2;
}

.sweep-tracking-number {
  color: #050593;
  font-weight: 900;
  font-size: 18px;
  text-transform: uppercase;
}

.sweep-exception-text {
  color: #4d4d4d;
  font-weight: 900;
  font-size: 18px;
  text-transform: uppercase;
}

.sweep-exception-remedy-btn {
  width: 110px;
}

.viewport > video {
  height: 280px !important;
}
