.video {
  height: 100%;
  width: 100%;
  object-fit: cover;

  &::-webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none;
  }
}

.bg-black {
  background: #000;
}

.container {
  max-height: 720px;
  max-width: 1280px;
  overflow: hidden;
}

.canvas {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.btn-container {
  background-color: #fff;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  padding: 25px;
}

.primary-button {
  height: 36px;
  background-color: #050593;
  color: #fff;
  font-family: AvenirLTStdBook;
  font-size: 16px;
  min-width: 200px;
  border-radius: 4px;
  border: none;
  padding-top: 2px;
}

@media(max-width: 767px) {
  .btn-container {
    height: 125px;
  }
  .w-sm-100 {
    width: 100%;
  }

  .mb-sm-1 {
    margin-bottom: 1rem;
  }
}