.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 1;
}

.ant-table-body {
  overflow-y: hidden !important;
}

.ant-table-body:hover {
  overflow-y: scroll !important;
}

.ant-table-header {
  & table {
    & thead {
      & tr .ant-table-cell {
        color: #707070;
        font-size: 16px;
        font-family: AvenirLTStdHeavy;
        & .ant-typography {
          color: #707070;
          font-size: 16px;
          font-family: AvenirLTStdHeavy;
        }
      }
    }
  }
  border-bottom: 1px solid #ddd;
}
