.bg-container {
  width: 600px;
  margin-bottom: 20px;
  margin-top: 60px;

  .link-title {
    font-family: AvenirLTStdBook;
    font-size: 14px;
    line-height: 1.2;
    color: #1890ff;
    cursor: pointer;
  }

  .title {
    font-family: AvenirLTStdBook;
    font-size: 14px;
    line-height: 1.2;
    color: #000;
  }

  .bread-crumb-container {
    align-items: center;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  @media (max-width: 576px) {
    .bread-crumb-container {
      // margin-top: 0px;
      // margin-bottom: 0px;
    }
  }

  .bread-crumb {
    font-family: AvenirLTStdBook;
    margin-right: 8px;
    margin-left: 8px;
    font-size: 14px;
    color: #000;
  }

  .breadcrumb-divider::before {
    font-size: 16px;
    color: #000000;
  }

  .toggle-link {
    color: #1890ff;
    margin-right: 0.2rem;
    font-size: 16px;
    cursor: pointer;
    font-family: AvenirLTStdBook;
  }

  .toggle-text {
    margin-left: 0.2rem;
  }

  .margin-top-20 {
    margin-top: 20px;
  }

  .margin-top-30 {
    margin-top: 30px;
  }

  @media (max-width: 576px) {
    .margin-top-xs-30 {
      margin-top: 30px;
    }
  }

  .margin-bottom-20 {
    margin-bottom: 20px !important;
  }

  .bg-container-body {
    background-color: #fff;
    border: none;
    box-sizing: border-box;
    box-shadow: 0px 0px 4px 4px #f2f2f2;
    padding: 40px;

    .text-heading-black {
      font-family: AvenirLTStdHeavy;
      font-size: 20px;
      line-height: 1.2;
      color: #000000;
      margin-bottom: 0px;
    }

    .text-span-link {
      font-family: AvenirLTStdBook;
      font-size: 16px;
      line-height: 1.2;
      color: #1890ff;
      cursor: pointer;
    }

    .text-span-blue {
      font-family: AvenirLTStdBook;
      font-size: 16px;
      line-height: 1.2;
      color: #1890ff;
    }

    .text-span-black {
      font-family: AvenirLTStdBook;
      font-size: 16px;
      line-height: 1.2;
      color: #000000;
    }

    .text-span-black-bold {
      font-family: AvenirLTStdHeavy;
      font-size: 16px;
      line-height: 1.2;
      color: #000000;
    }

    .secondary-button {
      height: 36px;
      background-color: #fff;
      color: #050593;
      border: 1px solid #050593;
      font-family: AvenirLTStdBook;
      font-size: 16px;
      min-width: 200px;
      border-radius: 4px;
      padding-top: 2px;
    }

    .secondary-button-small {
      height: 36px;
      background-color: #fff;
      color: #050593;
      border: 1px solid #050593;
      font-family: AvenirLTStdBook;
      font-size: 16px;
      min-width: 100px;
      border-radius: 4px;
      padding-top: 2px;
    }

    .primary-button {
      height: 36px;
      background-color: #050593;
      color: #fff;
      font-family: AvenirLTStdBook;
      font-size: 16px;
      min-width: 200px;
      border-radius: 4px;
      border: none;
      padding-top: 2px;
    }

    .primary-button-small {
      height: 36px;
      background-color: #050593;
      color: #fff;
      font-family: AvenirLTStdBook;
      font-size: 16px;
      min-width: 100px;
      border-radius: 4px;
      border: none;
      padding-top: 2px;
    }

    @media (max-width: 400px) {
      .primary-button {
        min-width: 150px;
      }
    }

    .secondary-button-lg-xs {
      height: 36px;
      background-color: #fff;
      color: #050593;
      border: 1px solid #050593;
      font-family: AvenirLTStdBook;
      font-size: 16px;
      min-width: 200px;
      border-radius: 4px;
      padding-top: 2px;
    }

    @media (max-width: 400px) {
      .secondary-button-lg-xs {
        min-width: 100%;
      }
    }

    .gray-divider {
      margin-top: 30px;
      margin-bottom: 30px;
      background: #ddd;
    }

    .gray-divider-20 {
      margin-top: 20px;
      margin-bottom: 20px;
      background: #ddd;
    }

    .gray-divider-30 {
      margin-top: 30px;
      margin-bottom: 30px;
      background: #ddd;
    }

    .upload-photo-button {
      color: #979797;
      background-color: #eee;
      border: none;
      border-color: #eee;
      text-align: center;
      border-radius: 4px;
      cursor: pointer;
      font-family: AvenirLTStdBook;
      font-size: 16px;
    }

    @media (max-width: 576px) {
      .upload-photo-button {
        width: 100%;
      }
    }

    .disconnect-qz-tray-button {
      height: 36px;
      background-color: #fff;
      color: #e31920;
      border: 1px solid #e31920;
      font-family: AvenirLTStdBook;
      font-size: 16px;
      min-width: 100px;
      border-radius: 4px;
    }

    .padding-20 {
      padding: 20px;
    }

    .gray-bg {
      background-color: #f5f5f5;
    }

    .table-heading-margin {
      margin-left: 0.75rem;
    }

    .primary-color-icon {
      color: #1890ff;
      font-size: 16px;
      cursor: pointer;
    }

    .loading-spinner {
      font-size: 25px;
    }

    .loading-spinner-white {
      color: #fff;
    }

    .loading-spinner-blue {
      color: #050593;
    }
  }

  @media (max-width: 576px) {
    .bg-container-body {
      padding: 20px;
    }
  }
}

.form-label {
  font-family: AvenirLTStdBook;
  font-size: 16px;
  color: #000000;
  line-height: 1.2;
}

.bg-container-padding {
  padding: 10px 20px 10px 20px;
}

@media (max-width: 576px) {
  .bg-container {
    height: auto;
    width: 100%;
    padding: 0px;
    margin-top: 50px;
  }
}

.ant-form-item-explain {
  font-size: 16px;
  margin-top: 10px;
  font-family: AvenirLTStdBook;
  color: red !important;
}

.custom-select-style > .ant-select-selector {
  border-color: #aaa !important;
}

@media (max-width: 767px) {
  .full-button-width {
    width: 100%;
  }
}

.border-right-rad-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: none;
}

.border-left-rad-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.input-error {
  .ant-input {
    border-color: #e31920;
  }
}

.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-affix-wrapper:hover {
  background-color: #fff;
  border-color: #ff4d4f !important;
}

.ant-select-item {
  color: #000 !important;
}

.details-box {
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 4px 4px #f0f0f0;
}

.table-header--bottom-border {
  border-bottom: 1px solid #ddd;
  color: #707070;
  font-size: 16px;
  font-family: AvenirLTStdHeavy;
}

.table-header-extended {
  min-width: 110px;
}

.shipments-antd-pagination > .ant-pagination-item-active {
  background: #1890ff;
  border-color: #1890ff;
  border-radius: 4px;
}

.shipments-antd-pagination > .ant-pagination-item-active > a {
  color: #fff;
}

.shipments-antd-pagination > .ant-pagination-prev > .ant-pagination-item-link > .anticon-left > svg {
  margin-top: -7px !important;
}

.shipments-antd-pagination > .ant-pagination-next > .ant-pagination-item-link > .anticon-right > svg {
  margin-top: -7px !important;
}

.shipments-antd-pagination > .ant-pagination-options {
  display: none;
}

.filter-icon {
  color: #bbbbbb;
}

.hms-dropdown-menu > .ant-dropdown-menu {
  background-color: #fff !important;
  min-width: 500px;
  padding: 10px;
}

.hms-dropdown-menu > .ant-dropdown-menu > .ant-dropdown-menu-item > a {
  color: #fff !important;
  font-weight: bold !important;
}

.hms-dropdown-menu > .ant-dropdown-menu > .ant-dropdown-menu-item:hover {
  background-color: #fff !important;
  // color: #fff !important;
}

.hms-dropdown-menu > .ant-dropdown-menu > .ant-dropdown-menu-item-active:hover {
  background-color: #fff !important;
  // color: #fff !important;
}

.full-width-container {
  width: 100%;
  padding: 0px 20px 20px 20px;
}

.bg-yellow {
  background: #f9fe01;
}

.text-black {
  color: #000 !important;
}

// .filter-reset {
//   font-family: AvenirLTStdBook;
//   font-size: 16px;
//   color: #bbbbbb;
//   font-style: normal;
//   font-weight: 350;
//   text-decoration-line: underline;
//   margin-top: 10px;
// }

// .filter-button {
//   font-family: AvenirLTStdBook;
//   font-style: normal;
//   font-weight: 350;
//   font-size: 16px;
//   line-height: 19px;
//   color: #050593;
//   background-color: #fff;
//   border: 1px solid #050593;
//   margin-top: 10px;
//   height: 36px;
//   border-radius: 4px;
// }
//

.height-50vh {
  height: 50vh;
}

.line-height-36 {
  line-height: 36px;
}
