@import "./login.css";

@font-face {
  font-family: AvenirLTStdBook;
  src: url(../fonts/AvenirLTStd-Book.otf);
}

@font-face {
  font-family: AvenirLTStdHeavy;
  src: url(../fonts/AvenirLTStd-Heavy.otf);
}

/* * {
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
  font-size: 14px;
} */

* {
  box-sizing: border-box;
  font-family: "AvenirLTStdBook", "Lato";
  /* font-size: 14px; */
}

.container {
  max-width: unset;
  padding: unset;
}

html {
  background-color: #f4f4f4 !important;
}

body {
  background-color: #f4f4f4 !important;
}

.root-child-main-div {
  max-height: 100%;
  overflow: hidden;
}

/* @media (max-width: 600px) { */
.main-container {
  min-height: 85vh;
}
.cnt-2 {
  flex-grow: 1;
}
/* } */

.main-container h1 {
  color: #2b2e2c;
}

.top-container {
  background: #050593 !important;
}

.invisible-box-left {
  width: 123px;
}

.invisible-box {
  width: 105px;
}

.main-header-container {
  width: 480px;
  max-width: 96vw;
}

.logo-container {
  width: 90px;
  max-width: 80%;
}

.tick-container {
  width: 10px;
  height: 10px;
  margin-top: 9px;
  margin-left: 10px;
}

.dropdown-item {
  padding-left: 4px;
}

.content-container {
  width: 480px;
  max-width: 96vw;
}

.btn-primary {
  background: linear-gradient(170deg, #050593 0%, #000050 100%) !important;
  border: 0px !important;
}

.drawingBuffer {
  display: none;
}

video {
  width: 100%;
  height: 200px;
}

.breadcrumb {
  background: none;
  padding: 12px 0;
  padding-left: 3px;
}

.form-group {
  padding: 0 10px;
  padding-top: 8px;
}

.form-group label {
  padding-left: 2px;
}

.btn-language {
  font-size: 12pt;
  background: transparent;
  border: transparent;
}

.btn-language:focus {
  outline: none;
}

.hms-scanner-timeline > .ant-timeline-item > .ant-timeline-item-head {
  width: 20px !important;
  height: 20px !important;
}

.hms-scanner-timeline > .ant-timeline-item > .ant-timeline-item-head-blue {
  background-color: #130098 !important;
  border-color: #130098 !important;
}

.hms-scanner-timeline > .ant-timeline-item > .ant-timeline-item-head-gray {
  background-color: rgba(0, 0, 0, 0.25) !important;
  border-color: transparent;
}

.hms-scanner-timeline > .ant-timeline-item > .ant-timeline-item-tail {
  top: 20px !important;
  left: 10px !important;
  height: calc(100% - 20px) !important;
  border-left: 1px solid #282828 !important;
}

.hms-scanner-timeline-item {
  font-size: 16px !important;
}

.hms-scanner-timeline-item > .ant-timeline-item-content {
  top: -4px !important;
  left: 10px !important;
  font-size: 16px !important;
  font-family: AvenirLTStdBook !important;
  color: #000;
}

/* shipments */
.shipments-title {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
}

.shipment-filter-bg {
  border-radius: 10px;
  color: #000000 !important;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  cursor: pointer;
}

.selected-shipment-filter-bg {
  background: #106cff;
  border-radius: 10px;
  color: #fff !important;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  padding: 3px 10px !important;
}

.create-shipment-title {
  font-family: Lato !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 20px !important;
  line-height: 24px !important;
}

.partner-to-send-label {
  font-family: Lato !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #000000 !important;
}

.create-shipment-button {
  background: #050593;
  border-radius: 5px !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: #fff !important;
  width: 120px !important;
  height: 28px !important;
}

.add-cargo-button {
  background: #050593;
  border-radius: 5px !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: #fff !important;
  height: 31px !important;
  width: 100px !important;
}

.shipment-to-partner {
  font-family: Lato !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 20px !important;
  line-height: 24px !important;
  color: #000000 !important;
}

.partner-shipments-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: #106cff !important;
  margin: 0 5px;
}

.cargo-button {
  background: #050593 !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: #fff !important;
  flex-grow: 1;
  margin: 0 5px;
}

.cancel-cargo {
  background: #c70007;
  border-radius: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #ffffff;
  margin: 5px 10px;
  align-self: flex-end;
}

.create-cargo-label {
  font-family: Lato !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 20px !important;
  line-height: 24px !important;
  color: #000000 !important;
}

.cancel-cargo:hover {
  color: #ffffff;
}

.delete-parcel-button {
  background: #ffffff;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #c70007;
  margin: 5px 10px;
  border: 1px solid #c70007;
  width: 100px;
}

.delete-parcel-button:hover {
  background: #c70007;
  color: #ffffff;
}

.create-cargo-dropdown-label {
  font-family: Lato !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #000000 !important;
}

.shipments-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: #106cff !important;
}

.add-cargo-label {
  font-family: Lato !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 20px !important;
  line-height: 24px !important;
  color: #000000 !important;
}

.add-cargo-tracking-number {
  color: #106cff !important;
}

.shipment-link-color {
  color: #106cff !important;
}

.cargo-bg {
  background: #c4c4c4 !important;
}

.added-to-cargo-text {
  font-family: Lato !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 20px !important;
  line-height: 24px !important;
  color: #000000 !important;
}

.cargo-tracking-number {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 18px !important;
  line-height: 21px !important;
  color: #000000;
}

/* .ant-notification-topRight {
  right: 32% !important;
  top: 73% !important;
} */

.ant-notification-notice-message {
  margin-top: 0.25rem !important;
}

/* .ant-notification-close-x {
  display: none;
} */

.pb-1 {
  padding-bottom: 0.3rem !important;
}

.ant-dropdown-menu {
  background-color: #106cff !important;
  color: #fff !important;
  width: 180px !important;
}

.ant-dropdown-menu-item > a {
  color: #fff !important;
  font-weight: bold !important;
}

/* .ant-dropdown-menu-item:hover {
  background-color: #106cff !important;
  color: #fff !important;
} */

.partner-selected {
  background-color: #2b2e2c;
}

.help-block {
  color: #c70007;
}

.text-danger {
  margin-top: 10px;
  font-size: 16px;
}

.form-group.has-error input {
  border: solid 1px red;
}

.Toastify__toast-container {
  margin-top: 31rem !important;
  width: 440px !important;
}

.Toastify__toast-body {
  color: #000000 !important;
}

.Toastify__toast--success {
  border: 1px solid #000000 !important;
}

.Toastify__progress-bar {
  visibility: hidden !important;
}

.add-cargo-container > .ant-row .ant-form-item {
  width: 100% !important;
}

.w {
  width: 100% !important;
}

.pointer {
  cursor: pointer;
}

.Toastify__toast--warning {
  background: yellow !important;
  border: 1px solid #000 !important;
}

.okButton {
  background-color: #1abc9c !important;
  color: #fff !important;
  border-radius: 5px !important;
}

.okButton > span {
  font-family: AvenirLTStdBook;
  font-size: 16px;
  line-height: 1.2;
}

.cancelButton {
  background-color: #e31920 !important;
  color: #fff !important;
  border-radius: 5px !important;
}

.cancelButton > span {
  padding-left: 10px !important;
  padding-right: 10px !important;
  font-family: AvenirLTStdBook;
  font-size: 16px;
  line-height: 1.2;
}

.confirmation-modal {
  margin-top: 22rem !important;
  width: 460px !important;
}

.confirmation-modal > .ant-modal-content > .ant-modal-close {
  display: none !important;
}

.confirmation-modal > .ant-modal-content > .ant-modal-body {
  text-align: center !important;
}

.disposal-confirmation-modal {
  margin-top: 15rem !important;
  width: 480px !important;
}

.disposal-confirmation-modal > .ant-modal-content > .ant-modal-close {
  display: none !important;
}

.disposal-confirmation-modal > .ant-modal-content > .ant-modal-body {
  border: 1px solid #131313 !important;
  background-color: #bfbdbd;
}

.confimation-modal-text {
  font-family: AvenirLTStdBook;
  font-size: 16px;
  line-height: 1.2;
  color: #000000;
}

.disposal-confirmation-text {
  font-size: 20px;
  font-family: AvenirLTStdBook;
  line-height: 1.2;
  color: #000000;
  margin-bottom: 15px;
}

.disposal-confirmation-ok-button {
  min-height: 36px;
  width: 120px;
  background-color: #188c15;
  color: #fff;
  border-color: #188c15;
  font-family: AvenirLTStdBook;
  font-size: 16px;
  border-radius: 4px;
}

.disposal-confirmation-cancel-button {
  min-height: 36px;
  width: 120px;
  background-color: #050593;
  color: #fff;
  border-color: #050593;
  font-family: AvenirLTStdBook;
  font-size: 16px;
  border-radius: 4px;
  margin-right: 10px;
}

.confirmation-modal-text {
  font-family: AvenirLTStdBook;
  font-size: 16px;
  line-height: 1.2;
  color: #000000;
}

.remove-cargo-container > .ant-form-item {
  width: 74.5% !important;
}

.post-code-bg {
  background-color: #1dd1a1;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #fff;
  font-size: 32px !important;
}

.shipment-pagination > .ant-pagination-prev > .ant-pagination-item-link > .anticon-left > svg {
  margin-top: -7px !important;
}

.shipment-pagination > .ant-pagination-next > .ant-pagination-item-link > .anticon-right > svg {
  margin-top: -7px !important;
}

.shipment-pagination > .ant-pagination-options {
  display: none;
}

.choose-file-button {
  color: #000;
  background-color: #e1e1e1;
  border-color: #adadad;
}

.scanner-switch {
  height: 20px !important;
  background-color: linear-gradient(170deg, #050593 0%, #000050 100%) !important;
  margin-bottom: 2px;
}

.scanner-switch > .ant-switch-handle {
  top: 0.75px !important;
}

.zebra-video {
  /* border: 1px solid gray; */
  height: 280px !important;
  margin-bottom: 10px;
  background-color: #000;
}

.recipientDropdown {
  margin-bottom: 0px !important;
}

.shipment-back-button {
  background: #050593;
  border-radius: 5px !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: #fff !important;
  width: 120px !important;
  height: 28px !important;
  margin: 5px;
}

.download-image-text {
  color: #130098 !important;
  text-decoration: underline;
}

.toggle-container {
  margin-top: 0.7rem;
}

.breadcrumb-link {
  color: #1890ff !important;
  margin-left: 0.2rem;
  cursor: pointer;
}

.toggle-text {
  margin-left: 0.2rem;
}

.shipment-numbers-error {
  background-color: #ffbf00;
  margin-top: 10px;
  padding: 5px;
}

.shipment-numbers-error > p {
  font-size: 16px;
  color: #000000;
  font-family: AvenirLTStdBook;
  margin-bottom: 0px;
}

.blackRadioGroup > .ant-radio-wrapper-checked > .ant-radio-checked > .ant-radio-inner {
  border-color: #000000 !important;
}

.blackRadioGroup > .ant-radio-wrapper-checked > .ant-radio-checked > .ant-radio-inner::after {
  background-color: #000000 !important;
}

.blackCheckBox > .ant-checkbox-checked > .ant-checkbox-inner {
  background-color: #000000;
  border-color: #000000;
}

.fnt-16 {
  font-size: 16px !important;
}

.ui-update-alert {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 96vw;
}

.ui-update-alert .ant-alert-content {
  flex: unset;
}

.padding-20 {
  padding: 20px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}
